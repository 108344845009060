<template>
  <button @mouseenter="hoverAnimation.play()" @mouseleave="hoverAnimation.reverse()" class="btn__help">
    <img :src="$store.state.folderAssets + '/custom-ico/picto_aide.png'" alt="Picto aide" />
  </button>
</template>

<script>
import gsap from "gsap";
export default {
  name: "PopinHelp",
  data() {
    return {
      hoverAnimation: null,
    }
  },
  mounted() {
    this.hoverAnimation = gsap.timeline({ paused: true })
      .to(".btn__help", {
        duration: 0.5,
        x: 20,
        ease: "power2.inOut",
      })
  }
};
</script>

<style lang="scss" scoped>
.btn__help {
  position: absolute;
  left: -$padding-h;
  border-radius: 0 100px 100px 0;
  background-color: #fff;
  padding: 0.5rem $padding-h;
  outline: none;
  border: none;
  box-shadow: none;
  width: $imgWidth + 3 * $padding-h;
  text-align: right;
  cursor: pointer;

  img {
    width: $imgWidth;
    display: inline-block;
  }
}
</style>