<template>
    <div class="progressCircle">
        <svg class="progress" width="102" height="102" viewport="0 0 102 102" version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <circle r="46" cx="51" cy="51" fill="#ffffff" stroke-dasharray="290" stroke-dashoffset="0"></circle>
            <circle class="bar" r="46" cx="51" cy="51" fill="transparent" stroke-dasharray="290" stroke-dashoffset="0"
                :style="{
                    'stroke-dashoffset': -290 * ((100 - score) / 100) + 'px',
                }"></circle>
        </svg>
        <img :src="$store.state.folderAssets + `'/custom-ico/smile_${smileyScore}.png`" alt="smiley" />
    </div>
</template>

<script>
export default {
    name: "ProgressCircleScore",
    data() {
        return {
            smileyScore: "good",
        };
    },
    props: {
        score: {
            type: Number,
            default: 0,
        },
    },
    mounted() {
        this.setupScore()
    },
    methods: {
        setupScore() {
            this.scorePercent = (this.$store.state.answersLevel / this.$store.state.totalQuestion) * 100;
            if (this.scorePercent >= 70) {
                this.smileyScore = "perfect";
            } else if (this.scorePercent >= 50) {
                this.smileyScore = "good";
            } else {
                this.smileyScore = "bad";
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.progressCircle {
    position: relative;
    width: fit-content;

    svg {
        display: block;
        margin: 0 auto;
        overflow: hidden;
        transform: rotate(-90deg) rotateX(180deg);

        circle {
            stroke-dashoffset: 0;
            stroke-width: 10px;
            transition: stroke-dashoffset 0.5s ease-in-out;

            &:nth-child(2) {
                stroke: var(--main-bg-color);
            }
        }

        .bar {
            stroke: #fff;
        }
    }

    img {
        position: absolute;
        inset: 0;
        margin: auto;
        max-width: 4rem;
    }
}
</style>