<template>
  <div class="solutionDetails">
    <h3>{{ solutionTitle }}</h3>
    <p v-html="solutionExplication"></p>
  </div>
</template>

<script>
export default {
  name: "SolutionExplaination",
  props: {
    solutionTitle: {
      type: String,
      default: "",
    },
    solutionExplication: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.solutionDetails {
  padding: 20px;
  background-color: var(--main-bg-color);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 50%;
  color: var(--main-light-txt-color);

  h3 {
    font-size: $h3-font-size;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  p {
    line-height: 1.5;
  }
}
</style>