<template>
  <div class="notif" :class="'notif-' + status">
    <div class="notif__content">
      <div class="icon">
        <img v-if="type === 1" :src="$store.state.folderAssets + '/custom-ico/notif_play.png'"
          alt="icone lecture en cours" />
        <img v-if="type === 2" :src="$store.state.folderAssets + '/custom-ico/notif_talk.png'" alt="icone question" />
        <img v-if="type === 3" :src="$store.state.folderAssets + '/custom-ico/notif_cible.png'"
          alt="icone meilleure réponse" />
        <img v-if="type === 4" :src="$store.state.folderAssets + '/custom-ico/notif_check.png'"
          alt="icone bonne réponse" />
        <img v-if="type === 5" :src="$store.state.folderAssets + '/custom-ico/notif_faux.png'"
          alt="icone mauvaise réponse" />
      </div>
      <span v-html="text"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopinVideoNotif",
  data() {
    return {
      type: "",
    };
  },
  props: {
    status: {
      type: String,
      default: "lecture",
    },
    text: {
      type: String,
      default: "Lecture en cours",
    },
  },
  beforeMount() {
    switch (this.status) {
      case "lecture":
        this.type = 1;
        break;
      case "talk":
        this.type = 2;
        break;
      case "better":
        this.type = 3;
        break;
      case "good":
        this.type = 4;
        break;
      case "bad":
        this.type = 5;
        break;
    }
  },
};
</script>

<style lang="scss" scoped>
.notif {
  // position: absolute;
  top: 5rem;
  width: 100%;
  text-align: center;
  z-index: 1;

  &__content {
    position: relative;
    display: inline-block;
  }

  .icon {
    position: absolute;
    top: -0.7rem;
    left: -1.75rem;
    width: 4.8rem;
    height: 4rem;
    background-size: contain;
    bottom: 0;
    margin: auto;

    @media (max-width: $media-md) {
      background-color: var(--main-light-bg-color);
      border-radius: 9999px;
      height: 3rem;
      width: 3rem;
      top: 0.3rem;
      left: 0rem;
      display: flex;
    }

    img {
      max-height: 100%;

      @media (max-width: $media-md) {
        max-height: 60%;
        margin: auto;
      }
    }
  }

  span {
    font-weight: 400;
    font-size: $default-font-size;
    color: #25242a;
    text-align: center;
    line-height: 1.5rem;
    padding: 0.6rem 1.5rem 0.6rem 4.6rem;
    border-radius: 300px;
    background: #ffffff;
    display: inline-block;
    max-width: 60vw;

    @media (max-width: $media-md) {
      display: none;
    }
  }

  // &-lecture {
  //   .icon {
  //     background: url("~@/assets/img/notif_play.png") no-repeat left center;
  //     background-size: cover;
  //   }
  // }

  // &-talk {
  //   .icon {
  //     background: url("~@/assets/img/notif_talk.png") no-repeat left center;
  //     background-size: cover;
  //   }
  // }

  // &-better {
  //   .icon {
  //     background: url("~@/assets/img/notif_cible.png") no-repeat left center;
  //     background-size: cover;
  //   }
  // }

  // &-good {
  //   .icon {
  //     background: url("~@/assets/img/notif_check.png") no-repeat left center;
  //     background-size: cover;
  //   }
  // }

  // &-bad {
  //   .icon {
  //     background: url("~@/assets/img/notif_faux.png") no-repeat left center;
  //     background-size: cover;
  //   }
  // }
}
</style>