<template>
  <div class="activity">
    <video :style="'background-image: url(' + $store.state.isZipped + previousPoster + ')'" class="activity__video"
      width="500" height="350" :src="$store.state.isZipped + currentVideo" preload="auto"
      :poster="$store.state.isZipped + introPoster" @ended="videoIsEnded">
      Video cannot be displayed
      <track label="Français" kind="subtitles" srclang="fr" :src="currentSubtitles" default>
    </video>
    <!-- <button @click="createPdf" style="position: absolute; z-index: 30;">Generate pdf</button> -->

    <!-- Intro slider of activity -->
    <ActivityIntro :reactive="activityControl.introSliderReactive" @back-to-activity="inactiveIntroSlider"
      @end-intro="endIntroSlide" class="popin__intro"></ActivityIntro>

    <!-- Popins -->
    <transition @enter="enterHelpPopin" @leave="leaveHelpPopin">
      <PopinHelp @click="activeIntroSlider" v-if="activityControl.introEnded" class="popin__help" />
    </transition>

    <!-- <transition v-on:enter="enterVideoNotif">
      <PopinVideoNotif v-if="activityControl.introEnded && activityControl.videoPlayingPoppin" class="popin__video" />
    </transition> -->
    <transition v-on:enter="enterNotif" v-on:leave="leaveNotif">
      <PopinVideoNotif status="talk" :text="currentQuestionText"
        v-if="activityControl.answerAvailable && !$store.state.mobile" class="popin__video" />
    </transition>
    <transition v-on:enter="enterVideoNotif">
      <PopinVideoNotif :status="resultPopin.ico" :text="resultPopin.text"
        v-if="activityControl.progressBarAvailable && !$store.state.mobile" class="popin__result" />
    </transition>

    <!-- Progress circle for steps in question -->
    <transition name="fade">
      <ProgressCircle v-if="
        activityControl.introEnded && activityControl.progressCircleAvailable
      " class="progress__circle" />
    </transition>

    <!-- Answer sliders -->
    <transition-group @enter="enterAnswer" @leave="leaveAnswer">
      <AnswerSlider :customDataQuestion="introQuestion" v-if="activityControl.answerAvailable && !$store.state.mobile"
        @answer="answerIsClicked" class="answer" />
      <AnswersMobileSlider :customDataQuestion="introQuestion"
        v-if="activityControl.answerAvailable && $store.state.mobile" @answer="answerIsClicked" class="answer" />
    </transition-group>
    <transition @enter="enterMobileResult" @leave="leaveMobileResult">
      <AnswersMobileResult :status="resultPopin.ico" :text="resultPopin.text"
        v-if="$store.state.mobile && activityControl.progressBarAvailable" class="answer" />
    </transition>
    <!-- Progress bar -->
    <transition v-on:enter="enterBar">
      <ProgressBar v-if="activityControl.progressBarAvailable" class="progressBar" />
    </transition>

    <transition name="fade">
      <SolutionExplaination v-if="activityControl.solutionAvailable" :solutionExplication="solution.text"
        :solutionTitle="solution.title" class="solution" />
    </transition>

    <!-- End of activity -->
    <transition @enter="enterRecapActivity">
      <ActivityRecap @download-report="currentQuizStep = quizTimeline.identityForm" @restart-activity="restartActivity"
        v-if="activityControl.activityEnd && !activityControl.identityForm" class="recapActivity" />
    </transition>

    <transition @enter="enterIdentity">
      <ActivityUserIdentity @identity-validated="formSubmit" v-if="activityControl.identityForm" class="userIdentity" />
    </transition>
    <ActivityReport v-if="activityControl.displayReport" class="reportActivity" />
  </div>
</template>

<script>
import ActivityIntro from "./ActivityIntro.vue";
import PopinHelp from "./popin/PopinHelp.vue";
import PopinVideoNotif from "./popin/PopinVideoNotif.vue";
import ProgressCircle from "./progress/ProgressCircle.vue";
import AnswerSlider from "./AnswerSlider.vue";
import ProgressBar from "./progress/ProgressBar.vue";
import SolutionExplaination from "./SolutionExplaination.vue";
import ActivityRecap from "./ActivityRecap.vue";
import ActivityReport from "./ActivityReport.vue";
import ActivityUserIdentity from "./ActivityUserIdentity.vue";
import AnswersMobileSlider from "./answers/AnswersMobileSlider.vue";
import AnswersMobileResult from "./answers/AnswersMobileResult.vue";
import gsap from "gsap";
import html2pdf from 'html2pdf.js';


export default {
  components: {
    ActivityIntro,
    PopinHelp,
    PopinVideoNotif,
    ProgressCircle,
    AnswerSlider,
    ProgressBar,
    SolutionExplaination,
    ActivityRecap,
    ActivityReport,
    ActivityUserIdentity,
    AnswersMobileSlider,
    AnswersMobileResult
  },
  name: "MainActivity",
  data() {
    return {
      video: null,
      currentVideo: null,
      currentSubtitles: null,
      currentPoster: null,
      previousPoster: null,
      introPoster: null,
      currentQuestionText: "",
      // use to manage UI elements
      activityControl: {
        introSliderReactive: false,
        videoIntroEnded: false,
        introAnswer: true,
        introEnded: false,
        helpPopin: false,
        videoPlayingPoppin: true,
        videoQuestionEnded: false,
        answerAvailable: false,
        progressBarAvailable: false,
        progressCircleAvailable: false,
        solutionAvailable: false,
        activityEnd: false,
        identityForm: false,
        displayReport: false,
      },
      resultPopin: {
        ico: "better",
        text: "Bravo ! C’est LA réponse idéale",
      },
      solution: {
        title: null,
        text: null,
      },
      quizTimeline: {
        start: 'start',
        introVideo: 'introVideo',
        introVideoQuestion: 'introVideoQuestion',
        questionVideo: 'questionVideo',
        allQuestions: 'allQuestions',
        reactionVideo: 'reactionVideo',
        endQuestion: 'endQuestion',
        recap: 'recap',
        identityForm: 'identityForm',
        end: 'end',
      },
      currentQuizStep: 'questionVideo',
      introQuestion: {
        question: "",
        reponses: null,
      },
    };
  },
  mounted() {
    this.video = document.querySelector(".activity__video");
    if (this.$store.state.data.tuto.isAvailable) {
      this.currentQuizStep = this.quizTimeline.start;
    }
    this.quizTimelineController();
  },
  watch: {
    currentQuizStep: function (newVal, oldVal) {
      this.quizTimelineController();
      return oldVal
    }
  },
  methods: {
    endIntroSlide() {
      if (this.$store.state.data.tuto.isAvailable) {
        this.quizStepIncrement();
        return
      }
      this.playVideo();
    },
    quizStepIncrement() {
      let keys = Object.keys(this.quizTimeline);
      let currentIndex = keys.indexOf(this.currentQuizStep);
      this.currentQuizStep = this.quizTimeline[keys[currentIndex + 1]];
    },
    /**
     * A switch statement that is controlling the timeline of the activity
     **/
    quizTimelineController() {

      switch (this.currentQuizStep) {
        case this.quizTimeline.start:
          this.setupQuestion();
          this.setupIntroVideo();
          break;
        case this.quizTimeline.introVideo:
          this.playVideo();
          break;
        case this.quizTimeline.introVideoQuestion:
          // We overide the current question text with tuto question
          this.currentQuestionText = this.introQuestion.question;
          this.activityControl.answerAvailable = true;
          break;

        case this.quizTimeline.questionVideo:
          this.introPoster = null;
          this.setupQuestion();
          this.playVideo();
          this.introQuestion = null;
          break;
        case this.quizTimeline.allQuestions:
          this.setupAnswerDisplay();
          break;
        case this.quizTimeline.reactionVideo:
          this.activityControl.progressBarAvailable = true;
          this.playVideo();
          break;

        //TODO: What happend in end question ? 
        case this.quizTimeline.endQuestion:
          const conclusionData = this.$store.state.data.conclusion;
          if (conclusionData.isAvailable) {
            this.currentPoster = conclusionData.poster;
            this.currentSubtitles = conclusionData.subtitles;
            this.currentVideo = conclusionData.video;
            this.playVideo();

          } else {
            this.currentQuizStep = this.quizTimeline.recap;
          }
          break;
        case this.quizTimeline.recap:
          this.activityControl.activityEnd = true;
          break;
        case this.quizTimeline.identityForm:
          this.activityControl.identityForm = true;
          this.activityControl.displayReport = true;
          break;
        case this.quizTimeline.end:
          break;
      }
    },

    endIntroVideo() {
      this.playVideo();
      this.activityControl.introAnswer = false;
    },
    setupIntroVideo() {
      const dataTuto = this.$store.state.data.tuto;

      //TODO : Handle mobile poster/video
      this.currentVideo = dataTuto.video
      this.currentPoster = dataTuto.poster
      this.previousPoster = dataTuto.poster
      this.introPoster = dataTuto.poster
      this.currentSubtitles = dataTuto.subtitles

      this.introQuestion.question = dataTuto.question;
      // this.currentQuestionText = dataTuto.question;
      this.introQuestion.reponses = dataTuto.reponses;
    },
    // It's a method that is called when the component is mounted. It's used to setup the question and the
    // video.
    setupQuestion() {

      const currentQuestion = this.$store.state.data.quiz.items[this.$store.state.currentQuestion];

      this.currentQuestionText = currentQuestion.question;

      if (!this.$store.mobile) {
        this.currentVideo =
          currentQuestion.video;

        if (currentQuestion.poster != null) {
          this.currentPoster = currentQuestion.poster;
        } else {
          this.currentPoster = "/uploads/posters/missing.jpg"
        }


      } else {
        this.currentVideo =
          currentQuestion.videoMobile;

        if (currentQuestion.poster != null) {
          this.currentPoster = currentQuestion.posterMobile;
        } else {
          this.currentPoster = "/uploads/posters/missing.jpg"
        }
      }

      this.currentSubtitles = currentQuestion.subtitles


      if (this.$store.state.data.quiz.items[
        this.$store.state.currentQuestion
      ].poster != null) {
        this.currentPoster = this.$store.state.data.quiz.items[
          this.$store.state.currentQuestion
        ].poster;
      } else {
        this.currentPoster = "/uploads/posters/missing.jpg"
      }

      this.activityControl.videoQuestionEnded = false;
    },

    // It's a method that is called when the user click on an answer. It's used to store the answer in the
    // store, to check if the answer is correct or not and to setup the next question.
    answerIsClicked(number, index) {

      // Exception for IntroVideo
      if (this.currentQuizStep === this.quizTimeline.introVideoQuestion) {
        this.activityControl.answerAvailable = false;
        this.activityControl.progressCircleAvailable = false;
        this.activityControl.progressBarAvailable = false;
        this.quizStepIncrement();
        return;
      }
      // Store the answer in the $store for final screen.
      this.$store.commit("PUSH_ANSWER", number);
      // Manage display of UI elements
      this.activityControl.answerAvailable = false;
      this.activityControl.progressCircleAvailable = false;
      this.activityControl.progressBarAvailable = true;
      // Set the content of optional solution display UI element

      let selectedAnswer = this.$store.state.data.quiz.items[this.$store.state.currentQuestion].reponses[index];

      this.solution.title =
        selectedAnswer.reponse;
      this.solution.text =
        selectedAnswer.explication;

      // Check what to display in the result popin
      this.checkAnswer(number);

      // Setup the explanation video of the selected answer and play it
      if (!this.$store.mobile) {
        this.currentVideo = selectedAnswer.video;
        this.currentPoster = selectedAnswer.poster;
      } else {
        this.currentVideo = selectedAnswer.videoMobile;
        this.currentPoster = selectedAnswer.posterMobile;
      }
      this.currentSubtitles = selectedAnswer.subtitles;
      this.quizStepIncrement();
    },
    // It's a method that is called when the user click on an answer. It's used to store the answer in the
    checkAnswer(number) {
      switch (number) {
        case 1:
          this.resultPopin.ico = "better";
          this.resultPopin.text = "Bravo ! C’est LA réponse idéale";
          this.increaseAnswersLevel(1);
          break;
        case 2:
          this.resultPopin.ico = "good";
          this.resultPopin.text = "Bonne réponse !";
          this.increaseAnswersLevel(0.5);
          break;
        case 3:
          this.resultPopin.ico = "bad";
          this.resultPopin.text = "Mauvaise réponse ...";
          break;
      }
    },
    // Increase the level of the answers (for progress bar)
    increaseAnswersLevel(value) {
      setTimeout(() => {
        this.$store.commit("INCREASE_ANSWERS_LEVEL", value);
      }, 500);
    },
    // It's used to play the video with a delaie
    playVideo() {
      setTimeout(() => {
        this.video.play();
        setTimeout(() => {
          this.previousPoster = this.currentPoster;
        }, 1000);
        if (!this.activityControl.videoQuestionEnded) {
          this.activityControl.videoPlayingPoppin = true;
        }
      }, 600);
    },
    // It's a method that is called when the video is ended. It's used to check if the video is the
    // question video or the answer video. If it's the question video, it's used to display the answer
    // slider. If it's the answer video, it's used to setup the next question.
    videoIsEnded() {
      if (this.currentQuizStep === this.quizTimeline.introVideo) {
        this.quizStepIncrement();
        this.setupQuestion();
        return
      }

      if (this.currentQuizStep === this.quizTimeline.endQuestion) {
        this.quizStepIncrement();
        return
      }
      // Setup the answer display
      if (this.currentQuizStep === this.quizTimeline.questionVideo) {
        // Setup the next question
        this.currentQuizStep = this.quizTimeline.allQuestions;
      } else {
        // Check if the user is on the last question
        if (
          this.$store.state.currentQuestion ==
          this.$store.state.totalQuestion - 1
        ) {
          this.$store.commit("INCREASE_CURRENT_QUESTION");
          this.currentQuizStep = this.quizTimeline.endQuestion;
          return;
        }
        this.setupNextQuestion();
        this.currentQuizStep = this.quizTimeline.questionVideo;
      }
    },
    setupAnswerDisplay() {
      this.activityControl.videoQuestionEnded = true;
      this.activityControl.answerAvailable = true;
      this.activityControl.videoPlayingPoppin = false;
      this.activityControl.progressCircleAvailable = true;
    },
    setupNextQuestion() {
      this.$store.commit("INCREASE_CURRENT_QUESTION");
      this.activityControl.progressBarAvailable = false;

      // Check if there is a optional explanation text or setup next question
      if (this.solution.text !== null) {
        this.activityControl.solutionAvailable = true;
        setTimeout(() => {
          this.activityControl.solutionAvailable = false;
          this.setupQuestion();
          this.playVideo();
        }, 2000);
      } else {
        this.setupQuestion();
        this.playVideo();
      }
    },
    activeIntroSlider() {
      this.activityControl.introSliderReactive = true;
    },
    inactiveIntroSlider() {
      this.activityControl.introSliderReactive = false;
    },
    restartActivity() {
      window.location.reload();
    },
    createPdf() {
      html2pdf(document.querySelector(".reportActivity"), {
        margin: [1, 0, 1, 0],
        filename: "report.pdf",
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, windowWidth: 800 },
        jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' },
        pagebreak: { avoid: '.all-answers__element' }
      });
    },
    formSubmit() {
      this.currentQuizStep = this.quizTimeline.end;
      this.createPdf();
    },
    //
    // TRANSITIONS / ANIMATIONS ///////////////////////////////////////////////////////////////
    // It's a transition hook.
    enterNotif(el, done) {
      gsap.from(el, {
        duration: 0.5,
        y: "-150%",
        rotate: 0,
        opacity: 1,
        ease: "power2.out",
        onComplete: () => done(),
      });
    },
    // It's a transition hook.
    leaveNotif(el, done) {
      gsap.to(el, {
        duration: 0.5,
        y: "-150%",
        opacity: 0,
        ease: "power2.out",
        onComplete: () => done(),
      });
    },
    enterVideoNotif(el, done) {
      gsap.timeline().from(el, {
        duration: 0.5,
        y: "-150%",
        opacity: 0,
        ease: "power2.out",
      })
        .to(el, {
          duration: 0.5,
          y: "-150%",
          opacity: 0,
          ease: "power2.out",
          onComplete: () => done(),
        }, ">4");
    },
    enterBar(el, done) {
      gsap.timeline()
        .to(el, {
          duration: 0.5,
          x: 0,
          opacity: 1,
          ease: "power2.out",
        })
        .to(el, {
          duration: 0.5,
          x: "100%",
          opacity: 0,
          ease: "power2.out",
          onComplete: () => {
            this.activityControl.progressBarAvailable = false;
            done();
          },
        }, ">4");
    },
    leaveBar(el, done) {
      gsap.to(el, {
        duration: 0.5,
        x: "150%",
        opacity: 0,
        ease: "power2.out",
        onComplete: () => done(),
      });
    },
    enterHelpPopin(el, done) {
      gsap.from(el, {
        duration: 0.5,
        x: "-100%",
        opacity: 0,
        ease: "power2.out",
        onComplete: done,
      });
    },
    leaveHelpPopin(el, done) {
      gsap.to(el, {
        duration: 0.5,
        x: "-100%",
        opacity: 0,
        ease: "power2.out",
        onComplete: done,
      });
    },
    enterAnswer(el, done) {
      gsap.from(el, {
        duration: 0.5,
        y: "150%",
        opacity: 0,
        ease: "power2.out",
        onComplete: done,
      });
    },
    leaveAnswer(el, done) {
      gsap.to(el, {
        duration: 0.5,
        delay: 0.2,
        y: "150%",
        opacity: 0,
        ease: "power2.out",
        onComplete: done,
      });
    },
    enterMobileResult(el, done) {
      gsap.from(el, {
        duration: 0.5,
        delay: 0.8,
        y: "150%",
        opacity: 0,
        ease: "power2.out",
        onComplete: done,
      })
    },
    leaveMobileResult(el, done) {
      gsap.to(el, {
        duration: 0.5,
        delay: 0.5,
        y: "150%",
        opacity: 0,
        ease: "power2.out",
        onComplete: done,
      })
    },
    enterRecapActivity(el, done) {
      gsap.from(el, {
        duration: 0.5,
        y: "150%",
        opacity: 0,
        ease: "power2.out",
        onComplete: done,
      })
    },
    enterIdentity(el, done) {
      gsap.from(el, {
        duration: 0.5,
        x: "100%",
        ease: "power2.out",
        onComplete: done,
      })
    },
  },
};
</script>

<style lang="scss" scoped>
::cue {
  font: $h2-font-size sans-serif;
  background: #1d1d1ddb;
  border-radius: 20px;
}

.activity {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
    background-color: var(--main-bg-color);
    background-position: center;
    background-size: cover;
  }

  .popin {
    z-index: 5;

    &__intro {
      z-index: 15;
    }

    &__help {
      z-index: 10;
      position: absolute;
      top: $medium-margin;

      @media (max-width: $media-md) {
        top: 1.2rem;
      }
    }

    &__video {
      z-index: 5;
      position: absolute;
      top: $medium-margin;

      @media (max-width: $media-md) {
        right: calc($medium-margin + 24px);
        top: 24px;
        width: fit-content;
      }
    }

    &__result {
      z-index: 5;
      position: absolute;
      top: $medium-margin;
    }
  }

  .progress {
    &__circle {
      z-index: 5;
      position: absolute;
      top: $medium-margin;
      right: $medium-margin;

      @media (max-width: $media-md) {
        top: 1.1rem;
        right: 1.1rem;
      }
    }
  }

  .answer {
    z-index: 3;
    position: absolute;
    bottom: 1rem;
    right: 0;
    left: 0;

    @media (max-width: $media-md) {
      bottom: 0;
    }
  }

  .progressBar {
    z-index: 5;
    position: absolute;
    bottom: 0;
    right: 1rem;
    top: 0;
    margin: auto;
    transform: translateX(150%);
    opacity: 0;

    @media (max-width: $media-md) {
      top: 1.8rem;
      right: 1.8rem;
      margin: 0;
    }
  }

  .solution {
    z-index: 3;
    position: absolute;
    bottom: 1rem;
    right: 0;
    left: 0;
    margin: auto;
  }

  .recapActivity {
    position: absolute;
    z-index: 3;
  }

  .userIdentity {
    z-index: 15;
    position: absolute;
  }

  .reportActivity {
    z-index: -1;
    position: absolute;
    inset: 0;
  }
}
</style>