<template>
  <div :class="theme" class="button">
    {{ textButton }}
  </div>
</template>

<script>
export default {
  name: "RoundedButton",
  props: {
    textButton: {
      type: String,
      required: true,
      default: "textButton props is required",
    },
    theme: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  font-size: $min-font-size;
  font-weight: 400;
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 240px;
  width: fit-content;
  background-color: var(--main-accent-color, $main-accent-color);
  border-radius: 50px;
  color: var(--main-light-txt-color, $main-light-txt-color);
  cursor: pointer;
}

.light {
  background-color: transparent;
  border: 2px solid var(--main-accent-color, $main-accent-color);
}
</style>