<template>
  <div class="progressdiv" :data-percent="this.$store.state.currentQuestion">
    <svg class="progress" width="102" height="102" viewport="0 0 102 102" version="1.1"
      xmlns="http://www.w3.org/2000/svg" data-v-a6a8a5d8="">
      <circle fill="#ffffff" data-v-a6a8a5d8="" cx="51" cy="51" r="51"></circle>
      <circle class="bar" cx="51" cy="51" stroke-dasharray="290" stroke-dashoffset="0" data-v-a6a8a5d8="" :style="{
        'stroke-dashoffset':
          -290 + 290 *
          (this.$store.state.currentQuestion /
            this.$store.state.totalQuestion) +
          'px',
      }" fill="transparent" r="46"></circle>
    </svg>
    <div class="progress-text">
      {{
          this.$store.state.currentQuestion +
          "/" +
          this.$store.state.totalQuestion
      }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressCircle",
};
</script>

<style lang="scss" scoped>
.progressdiv {
  position: relative;

  .progress {
    display: block;
    margin: 0 auto;
    overflow: hidden;
    transform: rotate(-90deg) rotateX(180deg);

    circle {
      stroke-dashoffset: 0;
      stroke-width: 10px;
    }

    .bar {
      transition: stroke-dashoffset 1s ease;
      stroke: var(--main-accent-color);
    }

    &-text {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      text-align: center;
      transform: translateY(-50%);
      font-size: 1.2rem;
      line-height: 5.4rem;
      color: #25242a;
    }
  }
}
</style>