<template>
  <div id="app">
    <div class="transition-slide">
      <img onerror="this.style.display='none'" :class="loaderActive ? 'loader' : ''"
        :src="$store.state.folderAssets + '/custom-ico/logo.png'">
      <img onerror="this.style.display='none'" :src="$store.state.folderAssets + '/custom-ico/logo.png'">
    </div>
    <transition v-on:enter="enterTransition(el, done)" v-on:leave="leaveTransition(el, done)">
      <MainActivity v-if="dataLoaded">
      </MainActivity>
    </transition>
  </div>
</template>

<script>
// APP.vue
// Fetch data from data.json if ziped quiz version or from global var routeApi if online quiz (serve from symfony) 
import MainActivity from "./components/MainActivity.vue";
import store from "./store/index";
import gsap from "gsap";

export default {
  name: "App",
  components: {
    MainActivity,
  },
  store,
  data() {
    return {
      dataLoaded: false,
      loaderActive: true,
    };
  },
  mounted() {
    // Use local data.json for zip bundle
    fetch('./data.json')
      .then((response) => response.json())
      .then((data) => {
        // Store data in with Vuex
        store.commit("SET_DATA", data);
        store.commit("SET_FOLDER_ASSETS", ".");
        this.dataLoaded = true;
        this.setQuestionNumber();
      })
      .catch((e) => {
        // On error switch to online quiz setup 
        this.onlineQuizData();
      })
    if (window.innerWidth < 768) {
      this.$store.commit("SET_MOBILE", true);
    }
  },
  methods: {
    // Fetch data from var (only if data.json not available)
    onlineQuizData() {
      fetch(routeApi)
        .then((response) => response.json())
        .then((data) => {
          store.commit("SET_DATA", data);
          if (this.$store.state.data.hasCustomIcon) {
            store.commit("SET_FOLDER_ASSETS", data.folder);
          } else {
            store.commit("SET_FOLDER_ASSETS", "")
          }
          store.commit("SET_ZIPPED", "");
          this.dataLoaded = true;
          this.setQuestionNumber();
        })
    },
    setQuestionNumber() {
      store.commit(
        "SET_TOTAL_QUESTION",
        Object.keys(this.$store.state.data.quiz.items).length
      );
    },
    // TRANSITION HOOK
    enterTransition(el, done) {
      this.loaderActive = false;
      gsap.timeline({ onComplete: done }).to(".transition-slide", {
        duration: 0.5,
        x: "100%",
        ease: "power3.out",
      });
    },
    leaveTransition(el, done) {
      this.loaderActive = false;
      gsap.timeline({ onComplete: done }).to(".transition-slide", {
        duration: 0.5,
        x: 0,
        ease: "power3.in",
      });
    },
  },
};
</script>

<style lang="scss">
.transition-slide {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--main-bg-color);
  z-index: 9999;
  opacity: 1;

  >img {
    max-width: 4.5rem;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;

    &:nth-child(1) {
      filter: saturate(0%);
      clip-path: inset(100% 100% 100% 100%);
      z-index: 1;
    }
  }

  @keyframes loader {
    0% {
      clip-path: inset(100% 100% 100% 100%);
    }

    50% {
      clip-path: inset(0 100% 100% 100%);
    }

    100% {
      clip-path: inset(100% 100% 100% 100%);
    }
  }

  .loader {
    animation-name: loader;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.19, 1, .22, 1);
  }


}
</style>