<template>
  <div class="progress-bar-container">
    <div v-if="!$store.state.mobile" class="progress-bar">
      <div class="progress-bar__icon">
        <img :src="$store.state.folderAssets + '/custom-ico/smile_perfect.png'" alt="perfect score icon" />
        <div class="progress-bar__icon__inner" :style="'height:' + (progressBar - 87) * (100 / 13) + '%'"></div>
        <div class="progress-bar__icon__inner--full"></div>

      </div>
      <div class="progress-bar__icon">
        <img :src="$store.state.folderAssets + '/custom-ico/smile_good.png'" alt="good score icon" />
        <div class="progress-bar__icon__inner" :style="'height:' + (progressBar - 41) * (100 / 18) + '%'"></div>
        <div class="progress-bar__icon__inner--full"></div>

      </div>
      <div class="progress-bar__icon">
        <img :src="$store.state.folderAssets + '/custom-ico/smile_bad.png'" alt="bad score icon" />
        <div class="progress-bar__icon__inner" :style="'height:' + progressBar * (100 / 13) + '%'"></div>
        <div class="progress-bar__icon__inner--full"></div>
      </div>
      <div class="progress-bar__container">
        <div class="progress-bar__inner" :style="'height:' + progressBar + '%'"></div>
      </div>
    </div>
    <ProgressCircleScoreVue :score="progressBar" v-if="$store.state.mobile" />
  </div>
</template>

<script>
import ProgressCircleScoreVue from './ProgressCircleScore.vue';

export default {
  name: "ProgressBar",
  components: {
    ProgressCircleScoreVue,
  },
  data() {
    return {
      progressBar: 0,
    };
  },
  watch: {
    score() {
    },
  },
  mounted() {
    this.progressBar = (this.$store.state.answersLevel / this.$store.state.totalQuestion) * 100;
  },
  watch: {
    "$store.state.answersLevel": function (newVal, oldVal) {
      this.progressBar = (newVal / this.$store.state.totalQuestion) * 100;
    },
  },
  methods: {
    updateProgressBar(newScore) {
      this.progressBar = (newScore / this.$store.state.totalQuestion) * 100;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar-container {
  height: fit-content;
}

.progress-bar {
  width: 6rem;
  height: 30rem;
  position: relative;
  display: flex;

  &__icon {
    position: absolute;
    width: 5rem;
    height: 5rem;
    // background-color: var(--main-light-bg-color);
    border-radius: 50%;
    left: 0;
    right: 0;
    margin: auto;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    img {
      margin: auto;
      z-index: 1;
      max-height: 4.1rem;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 0;
      bottom: 0;
    }

    &:nth-child(3) {
      bottom: 0;
    }

    &__inner {
      position: absolute;
      width: 100%;
      height: 0;
      background-color: var(--main-bg-color);
      transition: height 0.5s ease-in-out;
      z-index: 0;

      &--full {
        position: absolute;
        width: calc(100% - 1px);
        height: calc(100% - 1px);
        background-color: var(--main-light-bg-color);
        z-index: -1;
        inset: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 50%;
      }
    }
  }

  &__container {
    margin: auto;
    height: 90%;
    width: 1.5rem;
    background-color: var(--main-light-bg-color);
    display: flex;
    align-items: flex-end;
  }

  &__inner {
    margin-top: auto;
    height: 90%;
    width: 100%;
    background-color: var(--main-bg-color);
    transition: height 0.5s ease-in-out;
  }
}
</style>