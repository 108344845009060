<template>
  <div class="report">
    <div class="report__container">
      <header>
        <img :src="$store.state.folderAssets + '/custom-ico/logo.png'" alt="logo" />
        <h2>Récapitulatif d'activité</h2>
      </header>
      <div class="report__short">
        <ul class="identity">
          <li><b>Nom : </b>{{ $store.state.identity.name }}</li>
          <li><b>Prénom : </b>{{ $store.state.identity.surname }}</li>
          <li><b>Date : </b>{{ date }}</li>
        </ul>
        <div class="answers">
          <h3>Vos réponses :</h3>
          <div class="answers__container">
            <div class="answers__element">
              <img :src="$store.state.folderAssets + '/custom-ico/notif_cible.png'" alt="icône meilleure réponse" />
              <span>{{ perfectAnswer }}</span>
              <p>réponses<br /> parfaites</p>
            </div>
            <div class="answers__element">
              <img :src="$store.state.folderAssets + '/custom-ico/notif_check.png'" alt="icône bonne réponse" />
              <span>{{ goodAnswer }}</span>
              <p>réponses<br /> correctes</p>
            </div>
            <div class="answers__element">
              <img :src="$store.state.folderAssets + '/custom-ico/notif_faux.png'" alt="icône mauvaise réponse" />
              <span>{{ badAnswer }}</span>
              <p>mauvaises<br /> réponses</p>
            </div>
          </div>
        </div>
        <div class="level">
          <h3>Etat de votre jauge :</h3>
          <div class="level__content">
            <div class="ico">
              <svg class="progress" width="102" height="102" viewport="0 0 102 102" version="1.1"
                xmlns="http://www.w3.org/2000/svg">
                <circle r="46" cx="51" cy="51" fill="#ffffff" stroke-dasharray="290" stroke-dashoffset="0"></circle>
                <circle class="bar" r="46" cx="51" cy="51" fill="transparent" stroke-dasharray="290"
                  stroke-dashoffset="0" :style="{
                    'stroke-dashoffset': -290 * ((100 - scorePercent) / 100) + 'px',
                  }"></circle>
              </svg>
              <img :src="$store.state.folderAssets + `/custom-ico/smile_${smileyScore}.png`" alt="smiley" />
            </div>
            <span>{{ scorePercent }}%</span>
          </div>
        </div>
      </div>
      <div class="all-answers">
        <div class="all-answers__element" v-for="(answer, index) in answers" :key="index">
          <header>
            <img :src="$store.state.folderAssets + `/custom-ico/notif_${answer.type}.png`" alt="" />
            <h4><b>Question {{ index }} :</b> {{ answer.title }}</h4>
          </header>
          <p v-html="answer.answer">
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivityReport",
  data() {
    return {
      date: "",
      perfectAnswer: 0,
      goodAnswer: 0,
      badAnswer: 0,
      scorePercent: 20,
      smileyScore: "good",
      answers: {}
    };
  },
  mounted() {
    this.setupAllAnswers()
    this.setupIdentity()
    let el = document.getElementById("report");
  },
  methods: {
    setupIdentity() {
      const date = new Date();
      this.date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    },
    setupCounter() {
      for (let i = 0; i < this.$store.state.answers.length; i++) {
        switch (this.$store.state.answers[i]) {
          case 1:
            this.perfectAnswer++;
            break;
          case 2:
            this.goodAnswer++;
            break;
          case 3:
            this.badAnswer++;
            break;
        }
      }
      this.scorePercent = Math.round((this.$store.state.answersLevel / this.$store.state.totalQuestion) * 100);
      if (this.scorePercent >= 70) {
        this.smileyScore = "perfect";
      } else if (this.scorePercent >= 50) {
        this.smileyScore = "good";
      } else {
        this.smileyScore = "bad";
      }
    },
    setupAllAnswers() {
      const myAnswers = this.$store.state.data.quiz.items;
      for (let i = 0; i < myAnswers.length; i++) {
        const answerTypeRegistered = this.$store.state.answers[i];
        const currentQuestionIndex = i + 1;
        this.answers[currentQuestionIndex] = {};
        switch (answerTypeRegistered) {
          case 1:
            this.answers[currentQuestionIndex].type = "cible";
            break;
          case 2:
            this.answers[currentQuestionIndex].type = "check";
            break;
          case 3:
            this.answers[currentQuestionIndex].type = "faux";
            break;
        }
        this.answers[currentQuestionIndex].title = myAnswers[i].question;
        for (let j = 0; j < myAnswers[i].reponses.length; j++) {
          const answer = myAnswers[i].reponses[j];
          if (answer.type == answerTypeRegistered) {
            this.answers[i + 1].answer = answer.reponse;
          }
        }
      }
      this.setupCounter()
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin report-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.report {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  background-color: var(--main-light-bg-color);

  &__container {
    width: 90%;
    margin: 0 auto;

    >header {
      display: flex;
      justify-content: space-between;

      img {
        max-width: 90px;
      }

      h2 {
        font-size: 22px;
        font-weight: 500;
      }
    }
  }

  &__short {
    margin: 30px 0;
    display: flex;
    justify-content: space-between;

    .identity {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 16px;

      b {
        font-weight: 500;
      }
    }

    .answers {
      display: flex;
      flex-direction: column;

      h3 {
        @include report-title;
      }

      &__container {
        display: flex;
        justify-content: space-between;
      }

      &__element {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        page-break-inside: avoid;
        line-height: 1.2;
        margin: 0 7px;

        span {
          justify-content: center;
          display: flex;
          min-width: 20px;
          width: fit-content;
          background-color: #f3f3f3;
          font-size: 12px;
          border-radius: 15px;
          padding: 2px 5px;
          margin: 5px 0;
        }

        img {
          max-width: 60px;
          margin: 5px 0;

        }

        p {
          max-width: 150px;
          text-align: center;
          font-size: 12px;
          margin: 5px 0;
        }
      }
    }

    .level {
      h3 {
        @include report-title;
      }

      .ico {
        position: relative;
        width: fit-content;

        svg {
          display: block;
          margin: 0 auto;
          overflow: hidden;
          transform-origin: center;
          // transform: rotate(-90deg) rotateX(180deg);

          circle {
            stroke-dashoffset: 0;
            stroke-width: 10px;

            &:nth-child(2) {
              stroke: var(--main-bg-color);
              transform-origin: center;
              transform: rotate(-90deg) rotateX(180deg);
            }
          }

          .bar {
            stroke: #fff;
          }
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          max-width: 75px;
        }
      }

      &__content {
        display: flex;

        span {
          display: flex;
          border-radius: 15px;
          padding: 0.3rem 0.7rem;
          background-color: #f3f3f3;
          height: fit-content;
          margin: auto 1rem;
          min-width: 2rem;
        }
      }
    }
  }

  .all-answers {
    margin-top: 6rem;

    &__element {
      margin-bottom: 1.5rem;

      header {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        font-size: 16px;

        b {
          font-weight: 600;
        }

        h4 {
          line-height: 1.5;
        }

        img {
          margin-right: 0.5rem;
          max-width: 2rem;
        }
      }

      p {
        line-height: 1.5;
        font-size: 14px;
      }
    }
  }
}
</style>