/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

import { createApp } from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import Store from "./store/index";

// any CSS you import will output into a single css file (app.css in this case)
import "@/assets/scss/main.scss";

createApp(App).use(Store).mount("#app");
