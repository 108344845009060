<template>
  <div class="recap">
    <h2>Votre score</h2>
    <div class="recap__content">
      <div class="recap__content__item">
        <img :src="$store.state.folderAssets + '/custom-ico/notif_cible.png'" alt="icone réponse parfaite" /><span>{{
            perfectAnswer
        }}</span>
        <p>réponses parfaites</p>
      </div>
      <div class="recap__content__item">
        <img :src="$store.state.folderAssets + '/custom-ico/notif_check.png'" alt="icone bonne réponse" /><span>{{
            goodAnswer
        }}</span>
        <p>réponses correctes</p>
      </div>
      <div class="recap__content__item">
        <img :src="$store.state.folderAssets + '/custom-ico/notif_faux.png'" alt="icone mauvaise réponse" /><span>{{
            badAnswer
        }}</span>
        <p>mauvaises réponses</p>
      </div>
    </div>
    <div class="recap__buttons">
      <RoundedButton @click="$emit('restartActivity')" theme="light" textButton="Recommencer" />
      <RoundedButton @click="$emit('downloadReport')" textButton="Mon rapport" />
    </div>
  </div>
</template>

<script>
import RoundedButton from "./buttons/RoundedButton.vue";

export default {
  name: "ActivityRecap",
  components: {
    RoundedButton,
  },
  data() {
    return {
      perfectAnswer: 0,
      goodAnswer: 0,
      badAnswer: 0,
    };
  },
  mounted() {
    for (let i = 0; i < this.$store.state.answers.length; i++) {
      if (this.$store.state.answers[i] == 1) {
        this.perfectAnswer++;
      } else if (this.$store.state.answers[i] == 2) {
        this.goodAnswer++;
      } else if (this.$store.state.answers[i] == 3) {
        this.badAnswer++;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.recap {
  width: fit-content;
  padding: 2rem;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  background-color: var(--main-bg-color);
  height: fit-content;
  text-align: center;
  color: var(--main-light-txt-color);
  text-align: center;

  @media (max-width: $media-md) {
    width: auto;
    inset: auto 0 0;
    border-radius: 30px 30px 0 0;
  }

  h2 {
    margin-bottom: 1.5rem;
    font-weight: 600;
  }

  &__content {
    display: flex;
    width: fit-content;
    margin: auto;
    justify-content: space-between;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0.5rem 0.5rem;

      img {
        max-width: 5rem;
        margin: 0.5rem auto;
      }

      span {
        font-size: $min-font-size;
        justify-content: center;
        color: var(--main-bg-color);
        margin: 0.5rem auto;
        display: flex;
        min-width: 2rem;
        background-color: var(--main-light-bg-color);
        border-radius: 15px;
        padding: 0.1rem;
      }

      p {
        margin: 0.5rem 0;
      }

      @media (max-width: $media-md) {
        p {
          display: none;
        }
      }
    }
  }

  &__buttons {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;

    @media (max-width: $media-md) {
      flex-direction: column-reverse;
    }

    >div {
      width: 100%;
      margin: 0 0.5rem;

      @media (max-width: $media-md) {
        width: auto;
      }
    }
  }
}
</style>