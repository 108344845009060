<template>
    <div class="identity">
        <div class="identity__content">
            <img :src="$store.state.folderAssets + '/custom-ico/logo.png'" alt="logo">
            <form action="#" class="form" method="post" novalidate="true" @submit.prevent="checkFormInputs">
                <div class="form__input">
                    <div class="form__input__labels">
                        <label for="name">Votre nom</label><span>Obligatoire</span>
                    </div>
                    <input :disabled="isDisabled" v-model="name" type="text" id="name" name="name" required="required">
                </div>
                <div class="form__input">
                    <div class="form__input__labels">
                        <label for="surname">Votre prénom</label><span>Obligatoire</span>
                    </div>
                    <input :disabled="isDisabled" v-model="surname" type="text" id="surname" name="surname"
                        required="required">
                </div>
                <input class="form__submit" type="submit" value="Télécharger mon rapport">
            </form>
            <RoundedButton @click="restartActivity" class="replay" textButton="Recommencer" theme="light" />
        </div>
    </div>
</template>

<script>
import RoundedButton from "./buttons/RoundedButton.vue";
export default {
    name: "ActivityUserIdentity",
    components: {
        RoundedButton
    },
    data() {
        return {
            name: "",
            surname: "",
            nameToSubmit: "",
            surnameToSubmit: "",
            isDisabled: false,
        };
    },
    methods: {
        // Checking if the form is valid and if it is, it is setting the name and surname to submit to the
        // store.
        checkFormInputs() {
            if (this.name.length > 0 && this.surname.length > 0) {


                // Avoid re-edit the name and surname
                if (!this.isDisabled) {
                    this.nameToSubmit = this.name;
                    this.surnameToSubmit = this.surname;
                }
                this.$store.commit('SET_IDENTITY', { name: this.nameToSubmit, surname: this.surnameToSubmit });
                this.$emit('identityValidated');
                this.isDisabled = true;
            }
        },
        restartActivity() {
            window.location.reload()
        },
    }

}
</script>

<style lang="scss" scoped>
.identity {
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: var(--main-bg-color);
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        img {
            max-width: 4rem;
            margin: auto auto 3rem;
        }

        .form {
            display: flex;
            flex-direction: column;
            color: var(--main-light-txt-color);

            >div {
                margin-bottom: 1.5rem;
            }

            &__input {
                display: flex;
                flex-direction: column;

                &__labels {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0.5rem;

                    label {
                        font-size: $label-form-font-size;
                    }

                    span {
                        font-size: $span-form-font-size;
                    }
                }

                input {
                    height: 1rem;
                    border: none;
                    outline: none;
                    padding: 0.5rem;
                    border-radius: 30px;
                    font-size: $default-font-size;
                }
            }

            &__submit {
                @include rounded-button;
                border: none;
                width: 100%;
            }
        }

        .replay {
            margin: auto 0 2rem;
        }
    }
}
</style>