import Vuex, { Store } from "vuex";
const state = {
  data: {},
  folderAssets: ".",
  isZipped: ".",
  answers: [],
  answersLevel: 0,
  currentQuestion: 0,
  totalQuestion: 10,
  mobile: false,
  identity: {
    name: "",
    surname: "",
  },
};
const mutations = {
  SET_DATA: (state, data) => {
    state.data = data;
  },
  SET_FOLDER_ASSETS: (state, folder) => {
    state.folderAssets = folder;
  },
  SET_ZIPPED: (state, folder) => {
    state.isZipped = folder;
  },
  SET_CURRENT_QUESTION: (state, number) => {
    state.currentQuestion = number;
  },
  INCREASE_CURRENT_QUESTION: (state) => {
    state.currentQuestion = state.currentQuestion + 1;
  },
  SET_TOTAL_QUESTION: (state, number) => {
    state.totalQuestion = number;
  },
  PUSH_ANSWER: (state, answer) => {
    state.answers.push(answer);
  },
  INCREASE_ANSWERS_LEVEL: (state, answer) => {
    state.answersLevel = state.answersLevel + answer;
  },
  SET_MOBILE: (state, mobile) => {
    state.mobile = mobile;
  },
  SET_IDENTITY: (state, identity) => {
    state.identity = identity;
  },
};
export default new Vuex.Store({
  state: state,
  mutations: mutations,
});
