<template>
    <div class="answers-result">
        <img v-if="type == 1" :src="$store.state.folderAssets + '/custom-ico/notif_play.png'"
            alt="icone lecture en cours" />
        <img v-if="type == 2" :src="$store.state.folderAssets + '/custom-ico/notif_talk.png'" alt="icone question" />
        <img v-if="type == 3" :src="$store.state.folderAssets + '/custom-ico/notif_cible.png'"
            alt="icone meilleure réponse" />
        <img v-if="type == 4" :src="$store.state.folderAssets + '/custom-ico/notif_check.png'"
            alt="icone bonne réponse" />
        <img v-if="type == 5" :src="$store.state.folderAssets + '/custom-ico/notif_faux.png'"
            alt="icone mauvaise réponse" />
        <h3 v-html="text"></h3>
        <p v-html="explication"></p>
    </div>
</template>

<script>
export default {
    name: "AnswersMobileResult",
    data() {
        return {
            type: "",
        };
    },
    props: {
        status: {
            type: String,
            default: "lecture",
        },
        text: {
            type: String,
            default: "Lecture en cours",
        },
        explication: {
            type: String,
            required: false,
        }
    },
    beforeMount() {
        switch (this.status) {
            case "lecture":
                this.type = 1;
                break;
            case "talk":
                this.type = 2;
                break;
            case "better":
                this.type = 3;
                break;
            case "good":
                this.type = 4;
                break;
            case "bad":
                this.type = 5;
                break;
        }
    },
}
</script>

<style lang="scss" scoped>
.answers-result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    background-color: var(--main-bg-color, $main-bg-color);
    padding: 2rem 5% 1rem;
    margin: 0;
    color: var(--main-light-txt-color);
    text-align: center;
    border-radius: 30px 30px 0 0;

    >img {
        position: absolute;
        max-width: 4rem;
        inset: -2rem 0 0 0;
        margin: 0 auto;
    }

    >h3 {
        line-height: 1.5;
        font-size: $h4-font-size;
        margin-bottom: 0.5rem;
    }

    >p {
        line-height: 1.5;
        font-size: $min-font-size;
    }

}
</style>