<template>
  <transition @enter="enterTransition" @leave="leaveTransition">
    <div v-if="introActive || reactive" class="activity-intro">
      <transition name="fade">
        <div class="slide-back" v-if="currentSlide > 0" @click.once="previous" :key="resetButtonOnce">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.6667 6.16665H3.52501L8.18334 1.50831L7.00001 0.333313L0.333344 6.99998L7.00001 13.6666L8.17501 12.4916L3.52501 7.83331H13.6667V6.16665Z"
              fill="white" />
          </svg>
          Retour
        </div>
      </transition>
      <div v-if="reactive" @click="endIntroToggle" class="cross">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.648 0L13.9792 1.33322L1.33125 14L0 12.6668L12.648 0Z" fill="#F4F7F6" />
          <path d="M1.352 0L0.0207594 1.33322L12.6688 14L14 12.6668L1.352 0Z" fill="#F4F7F6" />
        </svg>
      </div>
      <img :src="$store.state.folderAssets + '/custom-ico/logo.png'" alt="Logo TeachUp" />
      <div class="slider" :style="'min-height: ' + height + 'px'">
        <div class="slider__slide">
          <h2>{{ dataSlide.slides[0].title }}</h2>
          <p v-html="dataSlide.slides[0].text"></p>
          <RoundedButton class="button-activity" textButton="Suivant" @click.once="nextSlide" :key="resetButtonOnce">
          </RoundedButton>
        </div>
        <div class="slider__slide">
          <h2>{{ dataSlide.slides[1].title }}</h2>
          <p v-html="dataSlide.slides[1].text"></p>
          <PopinDefault class="poppin" text="test"></PopinDefault>
          <RoundedButton class="button-activity" textButton="Suivant" @click.once="nextSlide" :key="resetButtonOnce">
          </RoundedButton>
        </div>
        <div class="slider__slide">
          <h2>{{ dataSlide.slides[2].title }}</h2>
          <p v-html="dataSlide.slides[2].text"></p>
          <img height="102" width="102" :src="$store.state.folderAssets + '/custom-ico/smile_perfect.png'"
            alt="perfect score ico" />
          <RoundedButton v-if="!reactive" class="button-activity" textButton="Démarrer l'activité"
            @click.once="endIntro" :key="resetButtonOnce"></RoundedButton>
          <RoundedButton v-if="reactive" class="button-activity" textButton="Retour à l'activité"
            @click.once="endIntroToggle" :key="resetButtonOnce"></RoundedButton>
        </div>
      </div>
      <div class="activity-intro__footer">
        <ul>
          <li v-bind:class="currentSlide == 0 ? 'selected' : ''"></li>
          <li v-bind:class="currentSlide == 1 ? 'selected' : ''"></li>
          <li v-bind:class="currentSlide == 2 ? 'selected' : ''"></li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script>
import PopinDefault from "./popin/PopinDefault.vue";
import RoundedButton from "./buttons/RoundedButton.vue";
import { gsap } from "gsap";

export default {
  components: { RoundedButton, PopinDefault },
  name: "ActivityIntro",
  data() {
    return {
      currentSlide: 0,
      introActive: true,
      dataSlide: null,
      resetButtonOnce: 0,
      height: 0,
    };
  },
  props: {
    'reactive': {
      type: Boolean,
      default: false
    }

  },
  beforeMount() {
    this.dataSlide = this.$store.state.data.intro;
  },
  mounted() {
    document.addEventListener("resize", this.resize);
    this.resize();
  },
  unmounted() {
    document.removeEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      // Get the heigher slide from array
      let slides = document.querySelectorAll(".slider__slide");
      let slidesHeight = [];
      slides.forEach((slide) => {
        slidesHeight.push(slide.clientHeight);
      });
      slides = Math.max(...slidesHeight);
      this.height = slides;
    },
    endIntro() {
      this.introActive = false;
      this.$emit("endIntro");
      this.$parent.activityControl.introEnded = true;
      this.currentSlide = 0;
    },
    endIntroToggle() {
      this.$emit('backToActivity')
      this.currentSlide = 0
    },
    nextSlide() {
      if (this.currentSlide === 2) return;
      const newSlide = document.querySelector(
        `.slider__slide:nth-child(${this.currentSlide + 2})`
      );
      const endSlide = document.querySelector(
        `.slider__slide:nth-child(${this.currentSlide + 1})`
      );
      gsap
        .timeline()
        .to(endSlide, {
          duration: 0.5,
          x: "-150%",
          ease: "power3.in",
          onComplete: () => {
            this.currentSlide++;
          },
        })
        .to(
          newSlide,
          {
            duration: 0.5,
            x: 0,
            ease: "power3.out",
          },
          "<0.4"
        );
    },
    previous() {
      const newSlide = document.querySelector(
        `.slider__slide:nth-child(${this.currentSlide})`
      );
      const endSlide = document.querySelector(
        `.slider__slide:nth-child(${this.currentSlide + 1})`
      );
      gsap
        .timeline({
          onComplete: () => {
            this.resetButtonOnce++;
          },
        })
        .to(endSlide, {
          duration: 0.5,
          x: "150%",
          ease: "power3.in",
          onComplete: () => {
            this.currentSlide--;
          },
        })
        .to(
          newSlide,
          {
            duration: 0.5,
            x: "0%",
            ease: "power3.out",
          },
          "<0.4"
        );
    },
    // TRANSITION HOOKS
    leaveTransition(el, done) {
      gsap.to(el, {
        duration: 0.5,
        x: "-100vw",
        ease: "power3.in",
        onComplete: done,
      });
    },
    enterTransition(el, done) {
      gsap.from(el, {
        duration: 0.5,
        x: "-100vw",
        ease: "power3.out",
        onComplete: done,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-intro {
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 40%;
  margin: auto;
  background-color: var(--main-bg-color, $main-bg-color);
  border-radius: 20px;
  padding: 45px;
  overflow-x: hidden;

  @media (max-width: $media-md) {
    width: 90%;
    height: 100%;
    border-radius: 0;
    padding: 5%;
    display: flex;
    justify-content: space-around;
  }

  .slide-back,
  .cross {
    position: absolute;
    transition: color 0.3s ease;
    color: var(--main-light-txt-color, $main-light-txt-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    width: fit-content;

    svg {
      margin-right: 10px;

      path {
        transition: fill 0.3s ease;
      }
    }

    &:hover {
      transition: color 0.3s ease;
      color: darken($main-light-txt-color, 20%);

      svg>path {
        transition: fill 0.3s ease;
        fill: darken($main-light-txt-color, 20%);
      }
    }

    @media (max-width: $media-md) {
      top: 24px;
      left: 5%;
    }
  }

  .cross {
    right: 5%;

    @media (max-width: $media-md) {
      right: 5%;
      left: unset;
    }
  }

  img {
    width: 4rem;
    height: auto;
    display: flex;
    margin: 40px auto 40px auto;
  }

  .slider {
    position: relative;
    width: 100vw;

    &__slide {
      transform: translateX(150%);
      position: absolute;
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 40%;
      height: 100%;

      @media (max-width: $media-md) {
        width: 90%;
      }

      &:nth-child(3) {
        height: unset;
      }

      h2 {
        margin-bottom: 40px;
        font-size: $h2-font-size;
        font-weight: 500;
        color: var(--main-light-txt-color, $main-light-txt-color);
      }

      p {
        color: var(--main-light-txt-color, $main-light-txt-color);
        margin-bottom: 24px;

        p {
          margin-bottom: 24px;
        }
      }

      .poppin {
        margin: auto;
      }

      &:nth-child(1) {
        transform: translateX(0);
      }
    }

    .button-activity {
      margin: auto auto 0 auto;
    }
  }

  &__footer {
    margin-top: auto;

    ul {
      display: flex;
      margin: 40px auto 0 auto;
      width: fit-content;
      // gap: 10px;

      li {
        margin: 0 5px;
        transition: background-color 0.5s ease;
        width: 8px;
        height: 8px;
        background-color: var(--main-accent-color, $main-accent-color);
        border-radius: 50%;
      }

      .selected {
        transition: background-color 0.5s ease;
        background-color: var(--main-light-txt-color, $main-light-txt-color);
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-enter-from,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}
</style>