<template>
  <div class="answer-slider">
    <img :src="$store.state.folderAssets + '/custom-ico/notif_talk.png'" alt="icon question">
    <h3 v-html="dataQuestion.question"></h3>
    <div class="answer-slider__container" :style="'min-height:' + answerHeight + 'px'">
      <div v-if="currentSlide > 0" @click="previousAnswer" class="answer-slider__arrow left">
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.41 10.59L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.59Z" fill="#F4F7F6" />
        </svg>
      </div>
      <div v-if="currentSlide < dataQuestion.reponses.length - 1" @click="nextAnswer"
        class="answer-slider__arrow right">
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.589999 1.41L5.17 6L0.59 10.59L2 12L8 6L2 5.24537e-07L0.589999 1.41Z" fill="#F4F7F6" />
        </svg>
      </div>
      <div class="answer-slider__pin">
        <ul>
          <li v-for="(reponse, index) in dataQuestion.reponses" :key="index"
            :class="currentSlide == index ? 'selected' : ''">
          </li>
        </ul>
      </div>
      <div class="answer-slider__element" v-for="(reponse, index) in dataQuestion.reponses" :key="index">
        <span>Réponse {{ index + 1 }}</span>
        <p v-html="reponse.reponse"></p>
        <RoundedButton @click="$emit('answer', reponse.type, index)" textButton="Valider la
        réponse" class="button" />
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import RoundedButton from "../buttons/RoundedButton.vue";

export default {
  name: "AnswersMobileSlider",
  components: { RoundedButton },
  data() {
    return {
      currentSlide: 0,
      dataQuestion: null,
      answerHeight: null,
      slides: null,
    };
  },
  props: {
    customDataQuestion: {
      type: Object,
      default: null,
    },
  },
  beforeMount() {
    if (this.customDataQuestion != null) {
      this.dataQuestion = this.customDataQuestion;
    } else {
      this.dataQuestion =
        this.$store.state.data.quiz.items[this.$store.state.currentQuestion];
    }
  },
  mounted() {
    var answerHeight = [];
    document.querySelectorAll(".answer-slider__element").forEach((element) => {
      answerHeight.push(element.offsetHeight);
    });
    answerHeight = Math.max(...answerHeight);
    this.answerHeight = answerHeight;
    this.slides = document.querySelectorAll(".answer-slider__element")
    gsap.set(this.slides[0], {
      x: 0,
      opacity: 1,
    });
  },
  methods: {
    nextAnswer() {
      const slideOut =
        this.slides[this.currentSlide];
      const slideIn =
        this.slides[this.currentSlide + 1];
      this.currentSlide++;
      if (this.currentSlide > 2) {
        this.currentSlide = 2;
        return;
      }
      gsap
        .timeline()
        .to(slideOut, {
          duration: 0.5,
          opacity: 0,
          x: "-150%",
          ease: "power2.out",
        })
        .to(
          slideIn,
          {
            duration: 0.5,
            opacity: 1,
            x: 0,
            ease: "power2.out",
          },
          0
        );
    },
    previousAnswer() {
      const slideOut =
        this.slides[this.currentSlide];
      const slideIn =
        this.slides[this.currentSlide - 1];
      this.currentSlide--;
      if (this.currentSlide < 0) {
        this.currentSlide = 0;
        return;
      }
      gsap
        .timeline()
        .to(slideOut, {
          duration: 0.5,
          opacity: 0,
          x: "150%",
        })
        .to(
          slideIn,
          {
            duration: 0.5,
            opacity: 1,
            x: 0,
          },
          0
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.answer-slider {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  color: var(--main-light-txt-color);
  background-color: var(--main-bg-color, $main-bg-color);
  width: 90%;
  padding: 2rem 5% 1rem;
  border-radius: 30px 30px 0 0;

  >img {
    position: absolute;
    max-width: 4rem;
    inset: -2rem 0 0 0;
    margin: 0 auto;
  }

  >h3 {
    line-height: 1.5;
    font-size: $h4-font-size;

  }

  &__arrow {
    z-index: 6;
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 999px;
    margin: auto;
    top: 0;
    background-color: var(--main-accent-color, $main-accent-color);
    cursor: pointer;
    justify-content: center;
    display: flex;
    align-items: center;

    &.left {
      left: 5%;
    }

    &.right {
      right: 5%;
    }
  }

  &__container {
    width: 100%;
    min-height: 9rem;
    overflow: hidden;
    position: relative;
    margin-top: 1rem;
  }

  &__element {
    transform: translateX(150%);
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;

    span {
      font-weight: 600;
      margin-bottom: 2rem;
      display: block;
    }

    p {
      line-height: 1.5;
    }

    .button {
      margin: auto;
      margin-top: 1rem;
    }

    // &:nth-of-type(3) {
    //     transform: translateX(0);
    //     opacity: 1;
    // }
  }

  &__pin {
    position: absolute;
    inset: 0.4rem 0 0;

    ul {
      margin-top: 40px;
      display: flex;
      margin: 1.5rem auto 0 auto;
      width: fit-content;
      gap: 10px;

      li {
        transition: background-color 0.5s ease;
        width: 8px;
        height: 8px;
        background-color: var(--main-accent-color, $main-accent-color);
        border-radius: 50%;
      }

      .selected {
        transition: background-color 0.5s ease;
        background-color: var(--main-light-txt-color, $main-light-txt-color);
      }
    }
  }
}
</style>