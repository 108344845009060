<template>
  <div class="perfect">
    <img :src="$store.state.folderAssets + '/custom-ico/notif_cible.png'" alt="réponse parfaite" />
    <div class="text">
      <p>{{ textPopin }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    textPopin: {
      type: String,
      required: false,
      default: "Bravo ! C’est LA réponse idéale",
    },
  },
};
</script>

<style lang="scss" scoped>
.perfect {
  display: flex;
  align-items: center;
  width: fit-content;
  position: relative;

  img {
    max-height: 4rem;
    width: auto;
    position: absolute;
  }

  .text {
    margin-left: 50px;
    padding-left: 30px;
    background-color: var(--main-light-txt-color, $main-light-txt-color);
    border-radius: 0 9999px 9999px 0;
    height: 55px;
    display: flex;

    p {
      font-size: $min-font-size;
      margin: auto 35px auto 16px;
      height: fit-content;
    }
  }
}
</style>


